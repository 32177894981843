import React from "react";
import { Route, Switch } from "react-router-dom";
import MyServices from "pages/MyServices";
import Services from "pages/Services";
import Messages from "pages/Messages";
import Logs from "pages/Logs";
import Records from "pages/Records";
import Users from "pages/Users";

export interface IPrivateRoutesProps {
  isAdmin: boolean;
}

const PrivateRoutes: React.FC<IPrivateRoutesProps> = ({
  isAdmin,
}: IPrivateRoutesProps) => (
  <Switch>
    <Route exact path="/">
      <MyServices />
    </Route>
    <Route path="/requests">
      <Records />
    </Route>
    {isAdmin && (
      <Route path="/all">
        <Services />
      </Route>
    )}
    {isAdmin && (
      <Route path="/messages">
        <Messages />
      </Route>
    )}
    {isAdmin && (
      <Route path="/logs">
        <Logs />
      </Route>
    )}
    {isAdmin && (
      <Route path="/users">
        <Users />
      </Route>
    )}
  </Switch>
);

export default PrivateRoutes;
