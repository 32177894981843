import React from "react";
import * as S from "./LogsList.styled";
import { useSelector } from "react-redux";
import Table from "./components/Table";
import { LogsListItemsSelector } from "./LogsListSlice";

const LogsList: React.FC = () => {
  // Список загруженных сервисных. центров
  const logs = useSelector(LogsListItemsSelector);

  return (
    <S.Wrap>
      <Table items={logs} />
    </S.Wrap>
  );
};

export default LogsList;
