import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";

import { RootState } from "store/store";
import { IMessagesListState, IMessage } from "./interfaces";
import { db } from "../../firebase";

// Селекторы
export const MessagesListSelector = (state: RootState): IMessagesListState => state.messagesList;
export const MessagesListLoadingSelector = createSelector(MessagesListSelector, ({ isLoading }) => isLoading);
export const MessagesListItemsSelector = createSelector(MessagesListSelector, ({ items }) => items);

export const fetchMessages = createAsyncThunk(
  "messagesList/fetchMessages",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "messages"));

      const response = await getDocs(params);
      const docs: IMessage[] = response.docs.map((snap) => {
        const document = snap.data();

        return ({
          id: snap.id,
          name: document.name,
          phone: document.phone,
          email: document.email,
          message: document.message,
        });
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IMessagesListState = {
  isLoading: false,
  items: [],
};

const messagesListSlice = createSlice({
  name: "messagesList",
  initialState,
  reducers: {},
  extraReducers: (builder => {
    builder
      .addCase(fetchMessages.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(fetchMessages.rejected, (state) => ({ ...state, isLoading: false }))
      .addCase(fetchMessages.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }));
    }),
});

export default messagesListSlice.reducer;