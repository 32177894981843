import { ServiceModalVisibleSelector } from "features/ServiceModal/ServiceModalSlice";
import ServiceModal from "features/ServiceModal";
import React from "react";
import { useAppSelector } from "hooks/useStore";
import { RecordModalVisibleSelector } from "features/RecordModal/RecordModalSlice";
import RecordModal from "features/RecordModal";
import UserModal from "features/UserModal";
import { UserModalVisibleSelector } from "features/UserModal/UserModalSlice";

const Windows: React.FC = () => {
  const isServiceModalVisible = useAppSelector(ServiceModalVisibleSelector);
  const isRecordModalVisible = useAppSelector(RecordModalVisibleSelector);
  const isUserModalVisible = useAppSelector(UserModalVisibleSelector);

  return (
    <>
      {isServiceModalVisible && <ServiceModal />}
      {isRecordModalVisible && <RecordModal />}
      {isUserModalVisible && <UserModal />}
    </>
  );
};

export default Windows;
