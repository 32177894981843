import React, { useEffect } from "react";
import { Layout, Breadcrumb, Button } from "antd";
import * as S from "./Records.styled";
import { fetchRecords } from "features/RecordsList/RecordsListSlice";
import { useAppDispatch } from "hooks/useStore";
import RecordsList from "features/RecordsList";
import { openNewRecordModal } from "features/RecordModal/RecordModalSlice";
import { IsAdminSelector, IsManagerSelector } from "features/User/UserSlice";
import { useSelector } from "react-redux";
import Meta from "components/Meta";

const { Content } = Layout;

const Records: React.FC = () => {
  const dispatch = useAppDispatch();

  const acceptedManager = useSelector(IsManagerSelector);
  const admin = useSelector(IsAdminSelector);

  useEffect(() => {
    dispatch(fetchRecords(admin));
  }, []);

  const onOpenNewRecordModal = () => dispatch(openNewRecordModal());

  return (
    <>
      <Meta pageName="records" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>client requests</Breadcrumb.Item>
        </Breadcrumb>
        <S.WorkSpace>
          <S.Header>
            <Button
              type="primary"
              size="large"
              onClick={onOpenNewRecordModal}
              block
              disabled={!acceptedManager}
            >
              Add record
            </Button>
          </S.Header>
          <RecordsList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Records;
