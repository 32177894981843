import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { RootState } from "store/store";
import { db } from "../../firebase";
import { IUser, IUserData, IUserState } from "./interfaces";

// Селекторы
export const UserSelector = (state: RootState): IUserState => state.user;

export const IsAuthenticatedSelector = createSelector(
  UserSelector,
  ({ uid }) => !!uid
);

export const IsAdminSelector = createSelector(
  UserSelector,
  ({ admin }) => !!admin
);

export const IsManagerSelector = createSelector(
  UserSelector,
  ({ manager }) => !!manager
);

export const NeedOnboardingSelector = createSelector(
  UserSelector,
  ({ onboarding }) => !!onboarding
);

export const UserLoadingSelector = createSelector(
  UserSelector,
  ({ isLoading }) => isLoading
);

// Экшены
export const setUser = createAction("user/setUser", (user: IUser) => ({
  payload: user,
}));
export const removeUser = createAction("user/removeUser");

export const fetchMyData = createAsyncThunk(
  "user/fetchMyData",
  async (uid: string, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "users"), where("uid", "==", uid));

      const response = await getDocs(params);
      const docs: IUserData[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          uid: document.uid,
          email: document.email,
          phone: document.phone,
          name: document.name,
          admin: document.admin,
          cars: document.cars,
          photo: document.photo,
          manager: document.manager,
          type: document.string,
          onboarding: document.onboarding,
          balance: document?.onboarding || 0,
          createdDate: document?.createdDate || Date.now().valueOf(),
        };
      });
      // TODO Костыль, пока fireBase, когда-нибудь исправить
      return docs[0];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание данных о пользователе
export const createMyData = createAsyncThunk(
  "user/createMyData",
  async (
    {
      uid,
      email,
      token,
      name,
      phone,
    }: {
      uid: string;
      email: string;
      token: string;
      name: string;
      phone: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const newUserTemplate = {
        admin: false,
        cars: [],
        type: "web",
        manager: false,
        email,
        name,
        phone,
        photo: "",
        onboarding: true,
        uid,
        createdDate: Date.now().valueOf(),
      };

      const newUserData = await addDoc(
        collection(db, "users"),
        newUserTemplate
      );
      const newDoc = {
        ...newUserTemplate,
        id: newUserData.id,
        token,
      };

      return newDoc;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUserState = {
  isLoading: false,
  token: "",
  id: "",
  uid: "",
  email: "",
  phone: "",
  name: "",
  admin: false,
  onboarding: false,
  cars: [],
  photo: "",
  newPhoto: "",
  manager: false,
  type: "web",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({
      ...state,
      uid: payload.uid,
      token: payload.token,
      email: payload.email,
    }),
    removeUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyData.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(fetchMyData.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchMyData.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }))
      .addCase(createMyData.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(createMyData.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(createMyData.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }));
  },
});

export default userSlice.reducer;
