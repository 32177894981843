import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  collection,
  query,
  getDocs,
  orderBy,
  // addDoc,
  // setDoc,
  // doc,
} from "firebase/firestore";

import { RootState } from "store/store";
import { ILibrariesState } from "./interfaces";
import {
  IBrand,
  ICategory,
  IMeasure,
  IPeriod,
  IStatus,
} from "interfaces/common";
import { db } from "../../firebase";

// Селекторы
export const LibrariesSelector = (state: RootState): ILibrariesState =>
  state.libraries;
export const BrandsLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingBrands }) => isLoadingBrands
);
export const CategoriesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingCategories }) => isLoadingCategories
);
export const PeriodsLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingPeriods }) => isLoadingPeriods
);
export const StatusesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingStatuses }) => isLoadingStatuses
);
export const TagsLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingTags }) => isLoadingTags
);
export const MeasuresLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingMeasures }) => isLoadingMeasures
);
export const BrandsSelector = createSelector(
  LibrariesSelector,
  ({ brands }) => brands
);
export const CategoriesSelector = createSelector(
  LibrariesSelector,
  ({ categories }) => categories
);
export const PeriodsSelector = createSelector(
  LibrariesSelector,
  ({ periods }) => periods
);
export const StatusSelector = createSelector(
  LibrariesSelector,
  ({ statuses }) => statuses
);
export const MeasuresSelector = createSelector(
  LibrariesSelector,
  ({ measures }) => measures
);

// Загрузка списка бренодов
export const fetchBrands = createAsyncThunk(
  "libraries/fetchBrands",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "brands"));

      const response = await getDocs(params);
      const docs: IBrand[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          name: document.name,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка единиц измерения
export const fetchMeasures = createAsyncThunk(
  "libraries/fetchMeasures",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "measures"));

      const response = await getDocs(params);
      const docs: IMeasure[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          title: document.title,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка категорий
export const fetchCategories = createAsyncThunk(
  "libraries/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "orgTypes"), orderBy("order"));

      const response = await getDocs(params);
      const docs: ICategory[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          name: document.name,
          title: document.title,
          primaryColor: document.primaryColor,
          secondaryColor: document.secondaryColor,
          display: document.display,
          order: document.order,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка периодов
export const fetchPeriods = createAsyncThunk(
  "libraries/fetchPeriods",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "periods"));
      const response = await getDocs(params);
      const docs: IPeriod[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          name: document.name,
          title: document.title,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка статусов
export const fetchStatuses = createAsyncThunk(
  "libraries/fetchStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "statuses"));

      const response = await getDocs(params);
      const docs: IStatus[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          name: document.name,
          color: document.color,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Отправка ручных данных
// export const sendData = createAsyncThunk(
//   "libraries/sendData",
//   async (data: any, { rejectWithValue }) => {
//     try {
//       const newData = {
//         results: data,
//       };

//       const res = await setDoc(doc(db, "library", "categories"), newData);

//       return res;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

const initialState: ILibrariesState = {
  isLoadingBrands: false,
  isLoadingCategories: false,
  isLoadingPeriods: false,
  isLoadingStatuses: false,
  isLoadingTags: false,
  isLoadingMeasures: false,
  brands: [],
  categories: [],
  periods: [],
  statuses: [],
  measures: [],
};

const LibrariesSlice = createSlice({
  name: "libraries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => ({
        ...state,
        isLoadingBrands: true,
      }))
      .addCase(fetchBrands.rejected, (state) => ({
        ...state,
        isLoadingBrands: false,
      }))
      .addCase(fetchBrands.fulfilled, (state, { payload }) => ({
        ...state,
        brands: payload.sort((a, b) => (a.name > b.name ? 1 : -1)),
        isLoadingBrands: false,
      }))
      .addCase(fetchCategories.pending, (state) => ({
        ...state,
        isLoadingCategories: true,
      }))
      .addCase(fetchCategories.rejected, (state) => ({
        ...state,
        isLoadingCategories: false,
      }))
      .addCase(fetchCategories.fulfilled, (state, { payload }) => ({
        ...state,
        categories: payload,
        isLoadingCategories: false,
      }))
      .addCase(fetchPeriods.pending, (state) => ({
        ...state,
        isLoadingPeriods: true,
      }))
      .addCase(fetchPeriods.rejected, (state) => ({
        ...state,
        isLoadingPeriods: false,
      }))
      .addCase(fetchPeriods.fulfilled, (state, { payload }) => ({
        ...state,
        periods: payload,
        isLoadingPeriods: false,
      }))
      .addCase(fetchStatuses.pending, (state) => ({
        ...state,
        isLoadingStatuses: true,
      }))
      .addCase(fetchStatuses.rejected, (state) => ({
        ...state,
        isLoadingStatuses: false,
      }))
      .addCase(fetchStatuses.fulfilled, (state, { payload }) => ({
        ...state,
        statuses: payload,
        isLoadingStatuses: false,
      }))
      .addCase(fetchMeasures.pending, (state) => ({
        ...state,
        isLoadingMeasures: true,
      }))
      .addCase(fetchMeasures.rejected, (state) => ({
        ...state,
        isLoadingMeasures: false,
      }))
      .addCase(fetchMeasures.fulfilled, (state, { payload }) => ({
        ...state,
        measures: payload,
        isLoadingMeasures: false,
      }));
  },
});

export default LibrariesSlice.reducer;
