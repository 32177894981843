import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";

import { RootState } from "store/store";
import { IUsersListState } from "./interfaces";
import { db } from "../../firebase";
import { IUserData } from "features/User/interfaces";
import { saveUser } from "features/UserModal/UserModalSlice";

// Селекторы
export const UsersListSelector = (state: RootState): IUsersListState =>
  state.usersList;

export const UsersListLoadingSelector = createSelector(
  UsersListSelector,
  ({ isLoading }) => isLoading
);

export const UsersListItemsSelector = createSelector(
  UsersListSelector,
  ({ items }) => items
);

export const QuerySelector = createSelector(
  UsersListSelector,
  ({ query }) => query
);

// Actions
export const setQuery = createAction("usersList/setQuery", (query: string) => ({
  payload: query,
}));

export const fetchUsers = createAsyncThunk(
  "usersList/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "users"));

      const response = await getDocs(params);
      const docs: IUserData[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          uid: document.uid,
          email: document.email,
          phone: document.phone,
          name: document.name,
          admin: document.admin,
          manager: document.manager,
          type: document.type,
          onboarding: document.onboarding,
          cars: document.cars,
          photo: document.photo,
          createdDate: document?.createdDate || Date.now().valueOf(),
          balance: document?.balance || 0,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUsersListState = {
  isLoading: false,
  items: [],
  query: "",
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setQuery: (state, { payload }) => ({
      ...state,
      query: payload.toLocaleLowerCase(),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchUsers.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchUsers.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }))
      .addCase(saveUser.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(saveUser.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(saveUser.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.map((item: IUserData) =>
          item.id === payload.id
            ? {
                ...item,
                ...payload.data,
              }
            : item
        ),
      }));
  },
});

export default usersListSlice.reducer;
