import React from "react";
import { Drawer, Button, Input, Select, Form, Space, InputNumber } from "antd";
import { closeUserModal, changeField } from "./UserModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { saveUser, UserModalSelector } from "./UserModalSlice";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

// const tailLayout = {
//   wrapperCol: { offset: 10, span: 14 },
// };

const { Option } = Select;

const UserModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    id,
    uid,
    name,
    phone,
    email,
    manager,
    type,
    admin,
    onboarding,
    cars,
    photo,
    createdDate,
    balance,
  } = useAppSelector(UserModalSelector);

  const onClose = () => dispatch(closeUserModal());

  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(changeField(event.target.name, event.target.value));

  const onChangeNumberField = (value: number) =>
    dispatch(changeField("balance", value));

  const onChangeSelect = (name: string, value: string | boolean) =>
    dispatch(changeField(name, value));

  const onSubmit = () => {
    const data = {
      id,
      uid,
      name,
      phone,
      email,
      manager,
      type,
      admin,
      onboarding,
      cars,
      photo,
      createdDate,
      balance,
    };

    return dispatch(saveUser({ id, data }));
  };

  return (
    <Drawer
      title="Edit user"
      placement="right"
      width={700}
      closable={false}
      onClose={onClose}
      visible
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            style={{ marginLeft: 8 }}
            htmlType="submit"
            type="primary"
            form="userForm"
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form
        {...layout}
        name="userForm"
        id="userForm"
        initialValues={{
          id,
          uid,
          name,
          phone,
          email,
          manager,
          type,
          admin,
          onboarding,
          cars,
          photo,
          balance,
        }}
        onFinish={onSubmit}
      >
        <Form.Item name="id" label="ID">
          <Input
            type="text"
            name="id"
            placeholder="not detected"
            onChange={onChangeTextField}
            disabled
          />
        </Form.Item>
        <Form.Item name="uid" label="UID">
          <Input
            type="text"
            name="uid"
            placeholder="not detected"
            onChange={onChangeTextField}
            disabled
          />
        </Form.Item>
        <Form.Item name="name" label="Name">
          <Input
            type="text"
            name="name"
            placeholder="not detected"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input
            type="text"
            name="phone"
            placeholder="not detected"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input
            type="text"
            name="email"
            placeholder="not detected"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="manager" label="Manager">
          <Select
            placeholder="not detected"
            defaultValue={manager}
            onChange={(value: boolean) => onChangeSelect("manager", value)}
          >
            <Option value={true}>yes</Option>
            <Option value={false}>no</Option>
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select
            placeholder="not detected"
            defaultValue={type}
            onChange={(value: string) => onChangeSelect("type", value)}
          >
            <Option value="web">web</Option>
            <Option value="app">app</Option>
          </Select>
        </Form.Item>
        <Form.Item name="admin" label="Admin">
          <Select
            placeholder="not detected"
            defaultValue={admin}
            onChange={(value: boolean) => onChangeSelect("admin", value)}
          >
            <Option value={true}>yes</Option>
            <Option value={false}>no</Option>
          </Select>
        </Form.Item>
        <Form.Item name="onboarding" label="Onboarding">
          <Select
            defaultValue={onboarding}
            onChange={(value: boolean) => onChangeSelect("onboarding", value)}
          >
            <Option value={true}>yes</Option>
            <Option value={false}>no</Option>
          </Select>
        </Form.Item>
        <Form.Item name="photo" label="Photo">
          <Input
            type="text"
            name="photo"
            placeholder="not detected"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="balance" label="Balance">
          <InputNumber
            name="balance"
            addonAfter="AED"
            placeholder="not detected"
            onChange={onChangeNumberField}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserModal;
