import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";

import { RootState } from "store/store";
import { ILog, ILogsListState } from "./interfaces";
import { db } from "../../firebase";

// Селекторы
export const LogsListSelector = (state: RootState): ILogsListState =>
  state.logsList;
export const LogsListLoadingSelector = createSelector(
  LogsListSelector,
  ({ isLoading }) => isLoading
);
export const LogsListItemsSelector = createSelector(
  LogsListSelector,
  ({ items }) => items
);

export const fetchLogs = createAsyncThunk(
  "logsList/fetchLogs",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "logs"));

      const response = await getDocs(params);
      const docs: ILog[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          type: document.type,
          logDate: document.logDate,
          email: document.email,
          details: document.details,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: ILogsListState = {
  isLoading: false,
  items: [],
};

const logsListSlice = createSlice({
  name: "logsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(fetchLogs.rejected, (state) => ({ ...state, isLoading: false }))
      .addCase(fetchLogs.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }));
  },
});

export default logsListSlice.reducer;
