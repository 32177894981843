import styled from "styled-components";

export const Wrap = styled.div`
  height: 100%;
  margin-bottom: 20px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 4px;
`;

export const Services = styled.div`
  display: flex;
  gap: 0px;
  flex-wrap: wrap;

  span {
    margin-bottom: 4px;
    margin-right: 4px;
  }
`;
