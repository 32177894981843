import { combineReducers } from "@reduxjs/toolkit";
import serviceModalSlice from "../features/ServiceModal/ServiceModalSlice";
import userModalSlice from "../features/UserModal/UserModalSlice";
import servicesListSlice from "../features/ServicesList/ServicesListSlice";
import messagesListSlice from "../features/MessagesList/MessagesListSlice";
import logsListSlice from "../features/LogsList/LogsListSlice";
import recordModalSlice from "../features/RecordModal/RecordModalSlice";
import recordsListSlice from "../features/RecordsList/RecordsListSlice";
import librariesSlice from "../features/Libraries/LibrariesSlice";
import librarySlice from "../features/Library/LibrarySlice";
import userSlice from "../features/User/UserSlice";
import usersListSlice from "../features/UsersList/UsersListSlice";

const rootReducer = combineReducers({
  serviceModal: serviceModalSlice,
  servicesList: servicesListSlice,
  recordModal: recordModalSlice,
  recordsList: recordsListSlice,
  messagesList: messagesListSlice,
  logsList: logsListSlice,
  libraries: librariesSlice,
  library: librarySlice,
  user: userSlice,
  usersList: usersListSlice,
  userModal: userModalSlice,
});

export default rootReducer;
