import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Input } from "antd";
import * as S from "./Users.styled";
import { useAppDispatch } from "hooks/useStore";
import UsersList from "features/UsersList";
import {
  fetchUsers,
  QuerySelector,
  setQuery,
  UsersListItemsSelector,
} from "features/UsersList/UsersListSlice";
import { useSelector } from "react-redux";
import { IUserData } from "features/User/interfaces";
import moment from "moment";
import { Line } from "@ant-design/plots";
import Meta from "components/Meta";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;

const Users: React.FC = () => {
  const [data, setData] = useState([]);

  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const users = useSelector(UsersListItemsSelector);
  const query = useSelector(QuerySelector);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    // Все даты регистраций
    const events = users.map((item: IUserData) => moment(item.createdDate));

    // Все даты регистраций в строках
    const dates = users.map((item: IUserData) =>
      moment(item.createdDate).format("DD.MM.YYYY")
    );

    // Начальная дата
    const startDate = moment.min(events);

    // Сегодняшняя дата
    const endDate = moment();

    const days = [startDate.format("DD.MM.YYYY")];

    while (startDate.add(1, "days").diff(endDate) <= 0) {
      days.push(startDate.clone().format("DD.MM.YYYY"));
    }

    setData(
      days.map((item: string) => ({
        date: item,
        users: dates.filter((day: string) => day === item).length,
      }))
    );
  }, [users]);

  const config = {
    data,
    padding: [40, 40, 60, 60],
    xField: "date",
    yField: "users",
    label: {},
    smooth: true,
    point: {
      size: 3,
      style: {
        stroke: "white",
        fill: "#5B8FF9",
        lineWidth: 2,
      },
    },
    slider: {
      start: 0.5,
      end: 1,
    },
  };

  const onSearch = (event) => dispatch(setQuery(event.target.value));

  return (
    <>
      <Meta pageName="users" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>{`users (${users.length})`}</Breadcrumb.Item>
        </Breadcrumb>
        <S.WorkSpace>
          <S.Search>
            <Input
              type="text"
              placeholder="Search"
              name="search"
              size="large"
              prefix={
                <S.SearchIcon>
                  <SearchOutlined style={{ color: "#B7B7B7" }} />
                </S.SearchIcon>
              }
              value={query}
              onChange={onSearch}
              allowClear
            />
          </S.Search>
          <S.Chart>
            <Line {...config} />
          </S.Chart>
          <UsersList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Users;
