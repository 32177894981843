import React, { useEffect } from "react";
import { Layout, Breadcrumb, Button, Input } from "antd";
import * as S from "./Services.styled";
import {
  fetchServices,
  QuerySelector,
  ServicesListItemsSelector,
  setQuery,
} from "features/ServicesList/ServicesListSlice";
import { useAppDispatch } from "hooks/useStore";
import ServicesList from "features/ServicesList";
import { openNewServiceModal } from "features/ServiceModal/ServiceModalSlice";
import { useSelector } from "react-redux";
import { IsManagerSelector } from "features/User/UserSlice";
import Meta from "components/Meta";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;

const Services: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const services = useSelector(ServicesListItemsSelector);
  const query = useSelector(QuerySelector);
  const acceptedManager = useSelector(IsManagerSelector);

  useEffect(() => {
    dispatch(fetchServices(true));
  }, []);

  const onOpenNewServiceModal = () => dispatch(openNewServiceModal());

  const onSearch = (event) => dispatch(setQuery(event.target.value));

  return (
    <>
      <Meta pageName="services" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>{`all technical services (${services.length})`}</Breadcrumb.Item>
        </Breadcrumb>
        <S.WorkSpace>
          <S.Search>
            <Input
              type="text"
              placeholder="Search"
              name="search"
              size="large"
              prefix={<S.SearchIcon><SearchOutlined style={{ color: "#B7B7B7" }} /></S.SearchIcon>}
              value={query}
              onChange={onSearch}
              allowClear
            />
          </S.Search>
          <S.Header>
            <Button
              type="primary"
              size="large"
              onClick={onOpenNewServiceModal}
              block
              disabled={!acceptedManager}
            >
              Add technical center
            </Button>
          </S.Header>
          <ServicesList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Services;
