import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { RootState } from "store/store";
import { IRecord, IRecordsListState } from "./interfaces";
import { auth, db } from "../../firebase";
import { addRecord, saveRecord } from "features/RecordModal/RecordModalSlice";

// Селекторы
export const RecordsListSelector = (state: RootState): IRecordsListState =>
  state.recordsList;
export const RecordsListLoadingSelector = createSelector(
  RecordsListSelector,
  ({ isLoading }) => isLoading
);
export const RecordsListItemsSelector = createSelector(
  RecordsListSelector,
  ({ items }) => items
);

export const fetchRecords = createAsyncThunk(
  "records/fetchRecords",
  async (isAdmin: boolean, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const uid = user?.uid;

      const params = isAdmin
        ? query(collection(db, "records"))
        : query(collection(db, "records"), where("organizationId", "==", uid));

      const response = await getDocs(params);
      const docs: IRecord[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          carBrandId: document.carBrandId,
          carModel: document.carModel,
          carYear: document.carYear,
          carVin: document.carVin,
          clientComment: document.clientComment,
          clientId: document.clientId,
          clientName: document.clientName,
          clientPhone: document.clientPhone,
          meetDate: document.meetDate,
          organizationComment: document.organizationComment,
          organizationId: document.organizationId,
          organizationName: document.organizationName,
          organizationType: document.organizationType,
          services: document.services,
          status: document.status,
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление существующего тех. центра
export const removeRecord = createAsyncThunk(
  "records/removeRecord",
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteDoc(doc(db, "records", id));

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IRecordsListState = {
  isLoading: false,
  items: [],
};

const recordsListSlice = createSlice({
  name: "records",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecords.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(fetchRecords.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchRecords.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }))
      .addCase(addRecord.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(addRecord.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(addRecord.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: [...state.items, payload],
      }))
      .addCase(saveRecord.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(saveRecord.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(saveRecord.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.map((item: IRecord) =>
          item.id === payload.id
            ? {
                ...item,
                ...payload.data,
              }
            : item
        ),
      }))
      .addCase(removeRecord.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(removeRecord.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(removeRecord.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.filter((item: IRecord) => item.id !== payload),
      }));
  },
});

export default recordsListSlice.reducer;
