import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import * as S from "./User.styled";
import { UserSelector } from "./UserSlice";

const User = () => {
  const { uid, email, name, phone, admin, manager } = useSelector(UserSelector);
  return (
    <S.User>
      {uid && (
        <S.Field>
          <S.Label>User ID</S.Label>
          <S.Value>{uid}</S.Value>
        </S.Field>
      )}
      {email && (
        <S.Field>
          <S.Label>Email</S.Label>
          <S.Value>{email}</S.Value>
        </S.Field>
      )}
      {name && (
        <S.Field>
          <S.Label>Name</S.Label>
          <S.Value>{name}</S.Value>
        </S.Field>
      )}
      {phone && (
        <S.Field>
          <S.Label>Phone</S.Label>
          <S.Value>{phone}</S.Value>
        </S.Field>
      )}
      <S.Field>
        <S.Label>
          {"Are you administrator? "}
          <Tooltip title="This permission is only available to employees and moderators of the Carcity mobile app.">
            <InfoCircleOutlined />
          </Tooltip>
        </S.Label>
        <S.Value>{admin ? "yes" : "no"}</S.Value>
      </S.Field>
      <S.Field>
        <S.Label>
          {"Are you accepted manager? "}
          <Tooltip title="After registering in the administration panel, you need to get confirmation of your account by our administrator. To do this, contact us in any way convenient for you.">
            <InfoCircleOutlined />
          </Tooltip>
        </S.Label>
        <S.Value>{manager ? "yes" : "no"}</S.Value>
      </S.Field>
    </S.User>
  );
};

export default User;
