import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { auth, db, storage } from "../../firebase";
import {
  IBrand,
  IImage,
  IService,
  IServiceData,
  ITag,
} from "interfaces/common";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { RootState } from "store/store";
import { IServiceModalState } from "./interfaces";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

// Открытие окна с новой целью
export const openNewServiceModal = createAction(
  "serviceModal/openNewServiceModal"
);

// Открытие окна с существующей целью
export const openOldServiceModal = createAction(
  "serviceModal/openOldServiceModal",
  (service: IService) => ({ payload: service })
);

// Открытие окна с новой целью
export const closeServiceModal = createAction("serviceModal/closeServiceModal");

// Изменение полей
export const changeField = createAction(
  "serviceModal/changeField",
  (field: string, value: string | number) => ({ payload: { field, value } })
);

// Изменение тегов
export const changeTags = createAction(
  "serviceModal/changeTags",
  (tags: ITag[]) => ({ payload: tags })
);

// Изменение фирм
export const changeBrands = createAction(
  "serviceModal/changeBrands",
  (brands: IBrand[]) => ({ payload: brands })
);

// Изменение категории
export const changeCategory = createAction(
  "serviceModal/changeCategory",
  (id: string) => ({ payload: id })
);

// Добавление периода
export const addPeriod = createAction("serviceModal/addPeriod");

// Изменение периода
export const changePeriod = createAction(
  "serviceModal/changePeriod",
  (id: string, value: string, field: string) => ({
    payload: { id, value, field },
  })
);

// Удаление периода
export const removePeriod = createAction(
  "serviceModal/removePeriod",
  (id: string) => ({ payload: id })
);

// Добавление оценки
export const addMark = createAction("serviceModal/addMark");

// Изменение оценки
export const changeMark = createAction(
  "serviceModal/changeMark",
  (id: string, value: string | number, field: string) => ({
    payload: { id, value, field },
  })
);

// Удаление оценки
export const removeMark = createAction(
  "serviceModal/removeMark",
  (id: string) => ({ payload: id })
);

// Добавление услуги
export const addService = createAction("serviceModal/addService");

// Изменение услуги
export const changeService = createAction(
  "serviceModal/changeService",
  (id: string, value: string | number, field: string) => ({
    payload: { id, value, field },
  })
);

// Удаление услуги
export const removeService = createAction(
  "serviceModal/removeService",
  (id: string) => ({ payload: id })
);

// Добавление избранного
export const addFavorites = createAction(
  "serviceModal/addFavorites",
  (value: string) => ({ payload: value })
);

// Удаление избранного
export const removeFavorites = createAction(
  "serviceModal/removeFavorites",
  (id: string) => ({ payload: id })
);

// Удаление изображения
export const removeImage = createAction(
  "serviceModal/removeImage",
  (uid: string) => ({ payload: uid })
);

// Удаление аватарки
export const removeAvatar = createAction("serviceModal/removeAvatar");

// Добавление нового сервиса
export const addOrganization = createAsyncThunk(
  "serviceModal/addOrganization",
  async (newDocData: IServiceData, { rejectWithValue }) => {
    try {
      const docRef = await addDoc(collection(db, "organizations"), newDocData);

      const newDoc: IService = {
        ...newDocData,
        id: docRef.id,
      };

      return newDoc;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка фотографий
export const uploadImage = createAsyncThunk(
  "services/uploadImage",
  async (image: File, { rejectWithValue }) => {
    try {
      const uniqId = Date.now();
      const imageRef = ref(storage, `images/${uniqId}-${image.name}`);
      let url = "";

      await uploadBytes(imageRef, image)
        .then((snapshot) => getDownloadURL(snapshot.ref))
        .then((u) => {
          url = u;
        });

      return {
        url,
        name: `${uniqId}-${image.name}`,
        imageId: v4(),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка аватарки
export const uploadAvatar = createAsyncThunk(
  "services/uploadAvatar",
  async (image: File, { rejectWithValue }) => {
    try {
      const imageRef = ref(storage, `logotypes/${image.name}`);
      let url = "";

      await uploadBytes(imageRef, image)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((u) => {
          url = u;
        });

      return {
        url,
        name: image.name,
        imageId: v4(),
        needBorder: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Изменение данных организации сервиса
export const saveOrganization = createAsyncThunk(
  "serviceModal/saveOrganization",
  async (
    { id, data }: { id: string; data: IServiceData },
    { rejectWithValue }
  ) => {
    try {
      await setDoc(doc(db, "organizations", id), data, { merge: true });

      return { id, data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IServiceModalState = {
  id: "",
  avatar: null,
  title: "",
  description: "",
  phone: "",
  email: "",
  site: "",
  inn: "",
  category: null,
  lifts: 1,
  latitude: "",
  longitude: "",
  tags: [],
  schedule: [],
  favorites: [],
  favoritesCount: 0,
  services: [],
  brands: [],
  organizationId: "",
  images: [],
  marks: [],
  isLoading: false,
  isVisible: false,
};

const serviceModalSlice = createSlice({
  name: "serviceModal",
  initialState,
  reducers: {
    openNewServiceModal: () => ({
      ...initialState,
      isVisible: true,
      organizationId: auth?.currentUser?.uid || "",
    }),
    closeServiceModal: () => initialState,
    openOldServiceModal: (_, { payload }) => ({
      ...initialState,
      ...payload,
      isVisible: true,
    }),
    changeField: (state, { payload }) => ({
      ...state,
      [payload.field]: payload.value,
    }),
    changeTags: (state, { payload }) => ({
      ...state,
      tags: payload,
    }),
    changeCategory: (state, { payload }) => ({
      ...state,
      category: payload,
    }),
    changeBrands: (state, { payload }) => ({
      ...state,
      brands: payload,
    }),
    addPeriod: (state) => ({
      ...state,
      schedule: [
        ...state.schedule,
        {
          id: Date.now().toString(),
          periodId: null,
          timeInterval: "",
        },
      ],
    }),
    changePeriod: (state, { payload }) => ({
      ...state,
      schedule: state.schedule.map((period) =>
        period.id === payload.id
          ? {
              ...period,
              [payload.field]: payload.value,
            }
          : period
      ),
    }),
    removePeriod: (state, { payload }) => ({
      ...state,
      schedule: state.schedule.filter((period) => period.id !== payload),
    }),
    addMark: (state) => ({
      ...state,
      marks: [
        ...state.marks,
        {
          id: Date.now().toString(),
          name: "",
          value: 0,
        },
      ],
    }),
    changeMark: (state, { payload }) => ({
      ...state,
      marks: state.marks.map((mark) =>
        mark.id === payload.id
          ? {
              ...mark,
              [payload.field]: payload.value,
            }
          : mark
      ),
    }),
    removeMark: (state, { payload }) => ({
      ...state,
      marks: state.marks.filter((mark) => mark.id !== payload),
    }),
    addService: (state) => ({
      ...state,
      services: [
        ...state.services,
        {
          id: Date.now().toString(),
          serviceName: "",
          measureId: "001",
          price: 0,
        },
      ],
    }),
    changeService: (state, { payload }) => ({
      ...state,
      services: state.services.map((period) =>
        period.id === payload.id
          ? {
              ...period,
              [payload.field]: payload.value,
            }
          : period
      ),
    }),
    removeService: (state, { payload }) => ({
      ...state,
      services: state.services.filter((service) => service.id !== payload),
    }),
    addFavorites: (state, { payload }) => ({
      ...state,
      favorites: [...state.favorites, payload],
      favoritesCount: state.favoritesCount + 1,
    }),
    removeFavorites: (state, { payload }) => ({
      ...state,
      favorites: state.favorites.filter((item) => item !== payload),
      favoritesCount: state.favoritesCount - 1,
    }),
    removeImage: (state, { payload }) => ({
      ...state,
      images: state.images.filter((image: IImage) => image.imageId !== payload),
    }),
    removeAvatar: (state) => ({
      ...state,
      avatar: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrganization.fulfilled, (state) => ({
        ...state,
        isVisible: false,
      }))
      .addCase(saveOrganization.fulfilled, (state) => ({
        ...state,
        isVisible: false,
      }))
      .addCase(uploadImage.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(uploadImage.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(uploadImage.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        images: [...state.images, payload],
      }))
      .addCase(uploadAvatar.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(uploadAvatar.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        avatar: payload,
      }));
  },
});

// Селекторы
export const ServiceModalSelector = (state: RootState): IServiceModalState =>
  state.serviceModal;
export const ServiceModalLoadingSelector = createSelector(
  ServiceModalSelector,
  ({ isLoading }) => isLoading
);
export const ServiceModalVisibleSelector = createSelector(
  ServiceModalSelector,
  ({ isVisible }) => isVisible
);

export default serviceModalSlice.reducer;
