import React, { useEffect } from "react";
import { Layout, Breadcrumb } from "antd";
import * as S from "./Messages.styled";
import { useAppDispatch } from "hooks/useStore";
import MessagesList from "features/MessagesList";
import { fetchMessages } from "features/MessagesList/MessagesListSlice";
import Meta from "components/Meta";

const { Content } = Layout;

const Records: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMessages());
  }, []);

  return (
    <>
      <Meta pageName="messages" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>landing messages</Breadcrumb.Item>
        </Breadcrumb>
        <S.WorkSpace>
          <MessagesList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Records;
