import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { collection, query, getDocs } from "firebase/firestore";

import { RootState } from "store/store";
import { ILibraryState } from "./interfaces";
import { db } from "../../firebase";

// Селекторы
export const LibrariesSelector = (state: RootState): ILibraryState =>
  state.library;

export const CategoriesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoading }) => isLoading
);

export const BrandsSelector = createSelector(
  LibrariesSelector,
  ({ brands }) => brands
);
export const CategoriesSelector = createSelector(
  LibrariesSelector,
  ({ categories }) => categories
);
export const PeriodsSelector = createSelector(
  LibrariesSelector,
  ({ periods }) => periods
);
export const StatusSelector = createSelector(
  LibrariesSelector,
  ({ statuses }) => statuses
);
export const MeasuresSelector = createSelector(
  LibrariesSelector,
  ({ measures }) => measures
);

// Загрузка всех списков
export const fetchLibrary = createAsyncThunk(
  "library/fetchLibrary",
  async (_, { rejectWithValue }) => {
    try {
      const params = query(collection(db, "library"));

      const response = await getDocs(params);

      const library = response.docs.reduce(
        // @ts-ignore
        (previousValue, currentValue) => {
          const document = currentValue.data();

          return {
            ...previousValue,
            [currentValue.id]: document.results,
          };
        },
        {}
      );

      return library;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: ILibraryState = {
  isLoading: false,
  brands: [],
  categories: [],
  periods: [],
  statuses: [],
  tags: [],
  measures: [],
};

const LibrarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLibrary.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchLibrary.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchLibrary.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }));
  },
});

export default LibrarySlice.reducer;
