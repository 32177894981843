import React from "react";
import { Menu } from "antd";
import {
  StarOutlined,
  ReadOutlined,
  BankOutlined,
  MessageOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import * as S from "./Navbar.styled";

export interface INavBarProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

const Navbar: React.FC<INavBarProps> = ({
  isAuthenticated,
  isAdmin,
}: INavBarProps) => {
  const { pathname } = useLocation();

  return (
    <S.Navbar>
      <Menu selectedKeys={[pathname]} mode="horizontal">
        {isAdmin && (
          <>
            <Menu.Item key="/all" icon={<StarOutlined />}>
              <NavLink to="/all">All tech. centers (admin)</NavLink>
            </Menu.Item>
            <Menu.Item key="/messages" icon={<MessageOutlined />}>
              <NavLink to="/messages">Messages (landing page)</NavLink>
            </Menu.Item>
            <Menu.Item key="/users" icon={<UserOutlined />}>
              <NavLink to="/users">Users</NavLink>
            </Menu.Item>
            <Menu.Item key="/logs" icon={<UnorderedListOutlined />}>
              <NavLink to="/logs">Logs</NavLink>
            </Menu.Item>
          </>
        )}
        {isAuthenticated && (
          <>
            <Menu.Item key="/" icon={<BankOutlined />}>
              <NavLink to="/">My tech. centers</NavLink>
            </Menu.Item>
            <Menu.Item key="/requests" icon={<ReadOutlined />}>
              <NavLink to="/requests">Client requests</NavLink>
            </Menu.Item>
          </>
        )}
      </Menu>
    </S.Navbar>
  );
};

export default Navbar;
