import React from "react";
import {
  Drawer,
  Button,
  Input,
  Select,
  InputNumber,
  Form,
  DatePicker,
  DatePickerProps,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ICategory, IJob, IStatus } from "interfaces/common";
import {
  RecordModalSelector,
  closeRecordModal,
  changeField,
  changeService,
  removeService,
  addService,
  addRecord,
  saveRecord,
} from "./RecordModalSlice";

import * as S from "./RecordModal.styled";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import {
  CategoriesSelector,
  StatusSelector,
} from "features/Libraries/LibrariesSlice";
import moment from "moment";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const { Option } = Select;
const { TextArea } = Input;

const ServiceModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    id,
    carBrandId,
    carModel,
    carVin,
    carYear,
    clientComment,
    clientId,
    clientName,
    clientPhone,
    meetDate,
    organizationComment,
    organizationId,
    organizationName,
    organizationType,
    services,
    status,
  } = useAppSelector(RecordModalSelector);
  const isNew = id === "";

  const onClose = () => dispatch(closeRecordModal());
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(changeField(event.target.name, event.target.value));
  const onChangeStatus = (newValue: string) =>
    dispatch(changeField("status", newValue));
  const onChangeCategory = (newValue: string) =>
    dispatch(changeField("organizationType", newValue));
  const onChangeDate: DatePickerProps["onChange"] = (date) =>
    dispatch(changeField("meetDate", date.valueOf()));
  const onChangeYear: DatePickerProps["onChange"] = (date) =>
    dispatch(changeField("carYear", date.year().toString()));
  const onAddService = () => dispatch(addService());
  const onChangeService = (
    serviceId: string,
    value: string | number,
    field: string
  ) => dispatch(changeService(serviceId, value, field));
  const onRemoveService = (serviceId: string) =>
    dispatch(removeService(serviceId));
  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(changeField(event.target.name, event.target.value));

  const onSubmit = () => {
    const data = {
      carBrandId,
      carModel,
      carVin,
      carYear,
      clientComment,
      clientId,
      clientName,
      clientPhone,
      meetDate,
      organizationComment,
      organizationId,
      organizationName,
      organizationType,
      services,
      status,
    };

    if (isNew) {
      return dispatch(addRecord(data));
    }

    return dispatch(saveRecord({ id, data }));
  };

  const allStatuses = useAppSelector(StatusSelector);
  const allCategories = useAppSelector(CategoriesSelector);

  return (
    <Drawer
      title={`${isNew ? "Adding a new" : "Editing a existing"} case`}
      placement="right"
      width={700}
      closable={false}
      onClose={onClose}
      visible
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            style={{ marginLeft: 8 }}
            htmlType="submit"
            type="primary"
            form="recordForm"
          >
            {isNew ? "Create" : "Save"}
          </Button>
        </Space>
      }
    >
      <Form
        {...layout}
        name="registryAdd"
        initialValues={{
          carBrandId,
          carModel,
          carVin,
          carYear: carYear !== "" ? moment(carYear, "YYYY") : carYear,
          clientComment,
          clientId,
          clientName,
          clientPhone,
          meetDate: moment(meetDate),
          organizationComment,
          organizationId,
          organizationName,
          organizationType,
          services,
          status,
        }}
        id="recordForm"
        onFinish={onSubmit}
      >
        <Form.Item name="carBrandId" label="Brand">
          <Input
            type="text"
            placeholder="Brand not specified"
            name="carBrandId"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="carModel" label="Model">
          <Input
            type="text"
            placeholder="Model not specified"
            name="carModel"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="carVin" label="VIN">
          <Input
            type="text"
            placeholder="VIN not specified"
            name="carVin"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="carYear" label="Year">
          <DatePicker
            name="carYear"
            picker="year"
            style={{ width: "100%" }}
            onChange={onChangeYear}
          />
        </Form.Item>
        <Form.Item name="clientId" label="Client ID">
          <Input
            type="text"
            placeholder="Client ID not specified"
            name="clientId"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="clientName" label="Client name">
          <Input
            type="text"
            placeholder="Client name not specified"
            name="clientName"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="clientPhone" label="Client phone">
          <Input
            type="text"
            placeholder="Client phone not specified"
            name="clientPhone"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="meetDate" label="Meeting date">
          <DatePicker
            name="meetDate"
            format="DD.MM.YYYY"
            style={{ width: "100%" }}
            onChange={onChangeDate}
          />
        </Form.Item>
        <Form.Item name="clientComment" label="Client comment">
          <TextArea
            placeholder="Client comment not specified"
            name="clientComment"
            maxLength={255}
            autoSize={{ minRows: 3, maxRows: 3 }}
            showCount={false}
            onChange={onChangeTextArea}
          />
        </Form.Item>
        <Form.Item name="organizationComment" label="Your comment">
          <TextArea
            placeholder="Your comment not specified"
            name="organizationComment"
            maxLength={255}
            autoSize={{ minRows: 3, maxRows: 3 }}
            showCount={false}
            onChange={onChangeTextArea}
          />
        </Form.Item>
        <Form.Item name="organizationId" label="Your ID">
          <Input
            type="text"
            placeholder="Your ID not specified"
            name="organizationId"
            onChange={onChangeTextField}
            disabled
          />
        </Form.Item>
        <Form.Item name="organizationName" label="Organization">
          <Input
            type="text"
            placeholder="Your name not specified"
            name="organizationName"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="organizationType" label="Your type">
          <Select defaultValue={organizationType} onChange={onChangeCategory}>
            {allCategories.map((item: ICategory) => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="services" label="Services">
          {services.map((service: IJob) => (
            <S.Service key={service.id}>
              <Input.Group compact>
                <Input
                  style={{ width: "55%" }}
                  placeholder="Service's name not specified"
                  value={service.serviceName}
                  onChange={(event) =>
                    onChangeService(
                      service.id,
                      event.target.value,
                      "serviceName"
                    )
                  }
                />
                <InputNumber
                  min={10}
                  max={100000}
                  step={1}
                  placeholder="1"
                  name="lifts"
                  style={{ width: "25%" }}
                  addonAfter="AED"
                  defaultValue={service.price}
                  value={service.price}
                  onChange={(value) =>
                    onChangeService(service.id, value, "price")
                  }
                />
                <Button
                  onClick={() => onRemoveService(service.id)}
                  style={{ width: "20%" }}
                >
                  Remove
                </Button>
              </Input.Group>
            </S.Service>
          ))}
          <Button
            onClick={onAddService}
            style={{ width: "100%" }}
            type="dashed"
          >
            <PlusOutlined />
            Add new service
          </Button>
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select defaultValue={status} onChange={onChangeStatus}>
            {allStatuses.map((item: IStatus) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ServiceModal;
