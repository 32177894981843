import React from "react";
import * as S from "./UsersList.styled";
import { useSelector } from "react-redux";
import Table from "./components/Table";
import { openOldUserModal } from "features/UserModal/UserModalSlice";
import { QuerySelector, UsersListItemsSelector } from "./UsersListSlice";
import { IUserData } from "features/User/interfaces";
import { useAppDispatch } from "hooks/useStore";

const UsersList: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const users = useSelector(UsersListItemsSelector);
  const query = useSelector(QuerySelector);

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onOpenOldUserModal = (user: IUserData) =>
    dispatch(openOldUserModal(user));

  const items = users.filter((item: IUserData) => {
    const check =
      item.name.toLocaleLowerCase().includes(query) ||
      item.phone.toLocaleLowerCase().includes(query) ||
      item.email.toLocaleLowerCase().includes(query);

    return !query ? true : check;
  });

  return (
    <S.Wrap>
      <Table items={items} onEdit={onOpenOldUserModal} />
    </S.Wrap>
  );
};

export default UsersList;
