import React, { useState } from "react";
import * as S from "./Table.styled";
import { Avatar, Button, Image, Table as TableAnt, Tag } from "antd";
import { ITableProps, ITableSort } from "features/UsersList/interfaces";
import { ICar, IUserData } from "features/User/interfaces";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";

const Table: React.FC<ITableProps> = ({ items, onEdit }) => {
  // Сортировка в табличке
  const [sort, setSort] = useState<ITableSort>({
    order: null,
    columnKey: null,
  });

  // Конфиг таблички для Ant Table
  const columns = [
    {
      title: "Avatar",
      dataIndex: "photo",
      key: "photo",
      sortOrder: sort.columnKey === "photo" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.photo < b.photo) return -1;
          if (a.photo > b.photo) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (photo: string) =>
        !!photo.length ? (
          <Avatar src={<Image src={photo} style={{ width: 32 }} />} />
        ) : (
          <Avatar size={32} icon={<UserOutlined />} />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortOrder: sort.columnKey === "name" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sortOrder: sort.columnKey === "phone" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.phone < b.phone) return -1;
          if (a.phone > b.phone) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortOrder: sort.columnKey === "email" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.email < b.email) return -1;
          if (a.email > b.email) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      sortOrder: sort.columnKey === "uid" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.uid < b.uid) return -1;
          if (a.uid > b.uid) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Created",
      dataIndex: "createdDate",
      key: "createdDate",
      sortOrder: sort.columnKey === "createdDate" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.createdDate < b.createdDate) return -1;
          if (a.createdDate > b.createdDate) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (createdDate: number) => moment(createdDate).format("DD.MM.YYYY"),
    },
    {
      title: "Is admin",
      dataIndex: "admin",
      key: "admin",
      sortOrder: sort.columnKey === "admin" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.admin < b.admin) return -1;
          if (a.admin > b.admin) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (admin: boolean) => (
        <Tag color={admin ? "purple" : "default"}>{admin ? "yes" : "no"}</Tag>
      ),
    },
    {
      title: "Onboarding",
      dataIndex: "onboarding",
      key: "onboarding",
      sortOrder: sort.columnKey === "onboarding" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.onboarding < b.onboarding) return -1;
          if (a.onboarding > b.onboarding) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (onboarding: boolean) => (
        <Tag color={onboarding ? "cyan" : "default"}>
          {onboarding ? "new" : "viewed"}
        </Tag>
      ),
    },
    {
      title: "Cars length",
      dataIndex: "cars",
      key: "cars",
      sortOrder: sort.columnKey === "cars" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.cars.length < b.cars.length) return -1;
          if (a.cars.length > b.cars.length) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (cars: ICar[]) => cars.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sortOrder: sort.columnKey === "type" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (type: string) => (
        <Tag color={type === "app" ? "magenta" : "geekblue"}>{type}</Tag>
      ),
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      sortOrder: sort.columnKey === "type" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IUserData, b: IUserData) => {
          if (a.manager < b.manager) return -1;
          if (a.manager > b.manager) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (manager: boolean) => (
        <Tag color={manager ? "blue" : "default"}>
          {manager ? "accepted" : "new"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      width: 128,
      render: (_: string, user: IUserData) => {
        return (
          <S.Actions>
            <Button onClick={() => onEdit(user)} size="small">
              edit
            </Button>
          </S.Actions>
        );
      },
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onChange = (_: any, filters: any, sorter: any) => {
    setSort(sorter);
  };

  return (
    <S.Wrap>
      <TableAnt
        columns={columns}
        dataSource={items}
        pagination={false}
        onChange={onChange}
      />
    </S.Wrap>
  );
};

export default Table;
