import React, { useState } from "react";
import {
  Drawer,
  Button,
  Input,
  Select,
  InputNumber,
  Form,
  Tag,
  Upload,
  Space,
  Rate,
  Typography,
} from "antd";
import {
  ClockCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  StarFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  IBrand,
  ICategory,
  IImage,
  IJob,
  IMark,
  IMeasure,
  IPeriod,
  IScheduleItem,
  ITag,
} from "interfaces/common";
import {
  ServiceModalSelector,
  closeServiceModal,
  changeField,
  changeBrands,
  changeCategory,
  changeTags,
  addPeriod,
  changePeriod,
  removePeriod,
  changeService,
  removeService,
  addService,
  addFavorites,
  removeFavorites,
  addOrganization,
  saveOrganization,
  uploadImage,
  removeImage,
  removeAvatar,
  uploadAvatar,
  removeMark,
  changeMark,
  addMark,
} from "./ServiceModalSlice";
import * as S from "./ServiceModal.styled";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import {
  BrandsSelector,
  CategoriesSelector,
  MeasuresSelector,
  PeriodsSelector,
} from "features/Libraries/LibrariesSlice";
import { IsAdminSelector } from "features/User/UserSlice";
import { useSelector } from "react-redux";
import { middle } from "utils/help";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const { Option } = Select;
const { Text } = Typography;

const ServiceModal: React.FC = () => {
  const [candidateToFavorites, setCandidateToFavorites] = useState<string>("");

  const dispatch = useAppDispatch();

  const {
    id,
    avatar,
    title,
    description,
    phone,
    site,
    email,
    inn,
    longitude,
    latitude,
    category,
    brands,
    lifts,
    tags,
    schedule,
    services,
    favorites,
    favoritesCount,
    organizationId,
    images,
    marks,
  } = useAppSelector(ServiceModalSelector);

  const isNew = id === "";
  const isAdmin = useSelector(IsAdminSelector);
  console.log(category);

  const onClose = () => dispatch(closeServiceModal());
  const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(changeField(event.target.name, event.target.value));
  const onChangeNumberField = (value: number) =>
    dispatch(changeField("lifts", value));
  const onChangeCategory = (id: string) => dispatch(changeCategory(id));
  const onChangeBrands = (value: IBrand[]) => dispatch(changeBrands(value));
  const onChangeTags = (value: ITag[]) => dispatch(changeTags(value));
  const onAddPeriod = () => dispatch(addPeriod());
  const onChangePeriod = (periodId: string, value: string, field: string) =>
    dispatch(changePeriod(periodId, value, field));
  const onRemovePeriod = (periodId: string) => dispatch(removePeriod(periodId));
  const onAddService = () => dispatch(addService());
  const onChangeService = (
    serviceId: string,
    value: string | number,
    field: string
  ) => dispatch(changeService(serviceId, value, field));
  const onRemoveService = (serviceId: string) =>
    dispatch(removeService(serviceId));
  const onAddMark = () => dispatch(addMark());
  const onChangeMark = (
    markId: string,
    value: string | number,
    field: string
  ) => dispatch(changeMark(markId, value, field));
  const onRemoveMark = (markId: string) => dispatch(removeMark(markId));

  const onAddFavorites = (value: string) => dispatch(addFavorites(value));

  const onRemoveFavorites = (favoriteId: string) =>
    dispatch(removeFavorites(favoriteId));

  const onChangeImages = ({ file }) => {
    const isNew = !images.find((image: IImage) => image.imageId === file.uid);

    if (isNew) {
      return dispatch(uploadImage(file.originFileObj));
    }

    return dispatch(removeImage(file.uid));
  };

  const onChangeAvatar = ({ file }) => {
    const isNew = !avatar || avatar?.name !== file.name;

    if (isNew) {
      return dispatch(uploadAvatar(file.originFileObj));
    }

    return dispatch(removeAvatar());
  };

  const onSubmit = () => {
    const data = {
      avatar,
      brands,
      category,
      description,
      email,
      favorites,
      favoritesCount,
      images,
      inn,
      phone,
      site,
      latitude,
      lifts,
      longitude,
      tags,
      title,
      schedule,
      services,
      organizationId,
      marks,
    };

    if (isNew) {
      return dispatch(addOrganization(data));
    }

    return dispatch(saveOrganization({ id, data }));
  };

  const allBrands = useAppSelector(BrandsSelector);
  const allCategories = useAppSelector(CategoriesSelector);
  const allPeriods = useAppSelector(PeriodsSelector);
  const allMeasures = useAppSelector(MeasuresSelector);

  return (
    <Drawer
      title={`${isNew ? "Create new" : "Edit"} tech. center`}
      placement="right"
      width={700}
      closable={false}
      onClose={onClose}
      open
      extra={
        <>
          <Space>
            {!!marks.length && (
              <>
                <Text>
                  {middle(
                    marks.map((mark: IMark) => mark.value),
                    marks.length
                  )}
                </Text>
                <StarFilled
                  style={{ color: "#fadb14", marginLeft: -4, marginRight: 14 }}
                />
              </>
            )}
            <Button onClick={onClose}>Cancel</Button>
            <Button htmlType="submit" type="primary" form="serviceForm">
              {isNew ? "Create" : "Save"}
            </Button>
          </Space>
        </>
      }
    >
      <Form
        {...layout}
        name="registryAdd"
        initialValues={{
          avatar,
          brands,
          category,
          description,
          email,
          favorites,
          favoritesCount,
          images,
          inn,
          phone,
          site,
          latitude,
          lifts,
          longitude,
          tags,
          title,
          schedule,
          services,
          organizationId,
          marks,
        }}
        id="serviceForm"
        onFinish={onSubmit}
      >
        {isAdmin && (
          <Form.Item name="organizationId" label="Organization ID">
            <Input
              type="text"
              placeholder="t42LStYiiXg8XHpzUqgxCOnNWme2"
              name="organizationId"
              onChange={onChangeTextField}
            />
          </Form.Item>
        )}
        <Form.Item
          name="title"
          label="Name"
          rules={[{ required: true, message: "Please input center's name!" }]}
        >
          <Input
            type="text"
            placeholder="zDegree DIP"
            name="title"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="description" label="Address">
          <Input
            type="text"
            placeholder="129, 318 Roud, Al Quoz Fourth - Dubai"
            name="description"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input
            type="text"
            placeholder="+971 4 3381551"
            name="phone"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="site" label="Site">
          <Input
            type="text"
            placeholder="www.volkswagen.ru"
            name="site"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input
            type="text"
            placeholder="info@volkswagen.ru"
            name="email"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="category"
          label="Main category"
          rules={[{ required: true, message: "Please select main category!" }]}
        >
          <Select
            defaultValue={category}
            onChange={onChangeCategory}
            placeholder="Select..."
          >
            {allCategories.map((item: ICategory) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="tags" label="Secondary categories">
          <Select
            mode="multiple"
            allowClear
            placeholder="Select..."
            defaultValue={tags}
            style={{ width: "100%" }}
            onChange={onChangeTags}
          >
            {allCategories.map((item: ICategory) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="inn" label="INN">
          <Input
            type="text"
            placeholder="0000000000"
            name="inn"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[{ required: true, message: "Please input latitude!" }]}
        >
          <Input
            type="text"
            name="latitude"
            placeholder="24.199216"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ required: true, message: "Please input longitude!" }]}
        >
          <Input
            type="text"
            name="longitude"
            placeholder="55.760372"
            onChange={onChangeTextField}
          />
        </Form.Item>
        {["001", "002", "007", "009"].includes(category) && (
          <Form.Item name="lifts" label="Lifts / boxes">
            <InputNumber
              min={1}
              max={500}
              step={1}
              name="lifts"
              style={{ width: "100%" }}
              addonAfter="lifts"
              onChange={onChangeNumberField}
            />
          </Form.Item>
        )}
        <Form.Item name="brands" label="Brands">
          <Select
            mode="multiple"
            allowClear
            placeholder="Select..."
            defaultValue={brands}
            style={{ width: "100%" }}
            onChange={onChangeBrands}
          >
            {allBrands.map((item: IBrand) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="schedule" label="Working hours">
          {schedule.map((period: IScheduleItem) => (
            <S.Period key={period.id}>
              <Input.Group compact>
                <Select
                  defaultValue={period.periodId}
                  style={{ width: "40%" }}
                  placeholder="Select..."
                  onChange={(value) =>
                    onChangePeriod(period.id, value, "periodId")
                  }
                >
                  {allPeriods.map((item: IPeriod) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{ width: "35%" }}
                  placeholder="08:00 - 20:00"
                  value={period.timeInterval}
                  onChange={(event) =>
                    onChangePeriod(
                      period.id,
                      event.target.value,
                      "timeInterval"
                    )
                  }
                />
                <Button
                  onClick={() => onRemovePeriod(period.id)}
                  style={{ width: "25%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Period>
          ))}
          {schedule.length < 7 && (
            <Button onClick={onAddPeriod} type="dashed">
              <ClockCircleOutlined />
              add interval
            </Button>
          )}
        </Form.Item>
        <Form.Item name="services" label="Services">
          {services.map((service: IJob) => (
            <S.Service key={service.id}>
              <Input.Group compact>
                <Input
                  style={{ width: "40%" }}
                  placeholder="Oil change"
                  value={service.serviceName}
                  onChange={(event) =>
                    onChangeService(
                      service.id,
                      event.target.value,
                      "serviceName"
                    )
                  }
                />
                <InputNumber
                  min={0}
                  max={100000}
                  step={1}
                  placeholder="1"
                  style={{ width: "20%" }}
                  defaultValue={service.price}
                  value={service.price}
                  onChange={(value) =>
                    onChangeService(service.id, value, "price")
                  }
                />
                <Select
                  defaultValue={
                    allMeasures.find(
                      (item: IMeasure) => item.id === service.measureId
                    )?.title || ""
                  }
                  style={{ width: "25%" }}
                  onChange={(value) =>
                    onChangeService(service.id, value, "measureId")
                  }
                >
                  {allMeasures.map((item: IMeasure) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
                <Button
                  onClick={() => onRemoveService(service.id)}
                  style={{ width: "15%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Service>
          ))}
          <Button onClick={onAddService} type="dashed">
            <PlusOutlined />
            add service
          </Button>
        </Form.Item>
        <Form.Item name="favorites" label="Followers">
          <Input.Group compact>
            <Input
              disabled={!isAdmin}
              value={candidateToFavorites}
              style={{ width: "65%" }}
              placeholder="au6wegfia67egf"
              onChange={(event) => setCandidateToFavorites(event.target.value)}
            />
            <Button
              disabled={
                candidateToFavorites.length === 0 ||
                favorites.includes(candidateToFavorites) ||
                !isAdmin
              }
              style={{ width: "15%" }}
              onClick={() => {
                onAddFavorites(candidateToFavorites);
                setCandidateToFavorites("");
              }}
            >
              add
            </Button>
            <Button
              disabled={!isAdmin}
              style={{ width: "20%" }}
              onClick={() => onAddFavorites(Date.now().toString())}
            >
              genegate
            </Button>
          </Input.Group>
          {favorites.map((favorite: string) => (
            <Tag key={favorite} style={{ marginTop: "8px" }}>
              {favorite}
              {isAdmin && (
                <CloseOutlined onClick={() => onRemoveFavorites(favorite)} />
              )}
            </Tag>
          ))}
        </Form.Item>
        <Form.Item name="avatar" label="Avatar">
          <Upload
            fileList={
              avatar
                ? [
                    {
                      uid: avatar.imageId,
                      name: avatar.name,
                      url: avatar.url,
                    },
                  ]
                : []
            }
            onChange={onChangeAvatar}
            customRequest={() => {}}
          >
            <Button
              icon={<UploadOutlined />}
              style={{
                width: "489px",
              }}
            >
              Upload avatar
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item name="images" label="Images">
          <Upload
            fileList={images.map((item: IImage) => ({
              uid: item.imageId,
              name: item.name,
              url: item.url,
            }))}
            onChange={onChangeImages}
            customRequest={() => {}}
          >
            <Button
              icon={<UploadOutlined />}
              style={{
                width: "489px",
              }}
            >
              Upload image
            </Button>
          </Upload>
        </Form.Item>
        {/*  */}
        <Form.Item name="marks" label="Marks">
          {marks.map((mark: IMark) => (
            <S.Period key={mark.id}>
              <Input.Group compact>
                <Input
                  style={{ width: "50%" }}
                  placeholder="Staff"
                  value={mark.name}
                  disabled={!isAdmin}
                  onChange={(event) =>
                    onChangeMark(mark.id, event.target.value, "name")
                  }
                />
                <Rate
                  defaultValue={mark.value}
                  onChange={(rate) => onChangeMark(mark.id, rate, "value")}
                  style={{ width: "35%", textAlign: "center" }}
                  disabled={!isAdmin}
                />
                <Button
                  onClick={() => onRemoveMark(mark.id)}
                  style={{ width: "15%" }}
                  disabled={!isAdmin}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Period>
          ))}
          <Button onClick={onAddMark} type="dashed" disabled={!isAdmin}>
            <ClockCircleOutlined />
            add mark
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ServiceModal;
