import React from "react";
import * as S from "./ServicesList.styled";
import { useSelector } from "react-redux";
import { auth } from "../../firebase";
import {
  QuerySelector,
  removeOrganization,
  ServicesListItemsSelector,
} from "./ServicesListSlice";
import Table from "./components/Table";
import { IService } from "interfaces/common";
import { openOldServiceModal } from "features/ServiceModal/ServiceModalSlice";
import { useAppDispatch } from "hooks/useStore";
import { CategoriesSelector } from "features/Libraries/LibrariesSlice";
import { IsAdminSelector, IsManagerSelector } from "features/User/UserSlice";
import { useLocation } from "react-router-dom";

const Services: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const services = useSelector(ServicesListItemsSelector);
  const isManager = useSelector(IsManagerSelector);
  const query = useSelector(QuerySelector);
  const isAdmin = useSelector(IsAdminSelector);

  // Словарь всех тегов
  const allCategories = useSelector(CategoriesSelector);

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onOpenOldServiceModal = (service: IService) =>
    dispatch(openOldServiceModal(service));

  // Удаление сервисного центра
  const onRemoveService = (id: string) => dispatch(removeOrganization(id));

  // Проверка страницы
  const { pathname } = useLocation();

  const items = services.filter((item: IService) => {
    const check =
      item.title.toLocaleLowerCase().includes(query) ||
      item.description.toLocaleLowerCase().includes(query) ||
      item.phone.toLocaleLowerCase().includes(query) ||
      item.email.toLocaleLowerCase().includes(query);

    return !query ? true : check;
  });

  return (
    <S.Wrap>
      <Table
        items={items.filter((item: IService) =>
          pathname === "/all"
            ? item
            : item.organizationId === auth?.currentUser?.uid || ""
        )}
        isManager={isManager}
        isAdmin={isAdmin && pathname === "/all"}
        allCategories={allCategories}
        onEdit={onOpenOldServiceModal}
        onRemove={onRemoveService}
      />
    </S.Wrap>
  );
};

export default Services;
