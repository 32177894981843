import React, { useEffect } from "react";
import { Layout, Breadcrumb } from "antd";
import * as S from "./Logs.styled";
import { useAppDispatch } from "hooks/useStore";
import { fetchLogs } from "features/LogsList/LogsListSlice";
import Meta from "components/Meta";
import LogsList from "features/LogsList";

const { Content } = Layout;

const Logs: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLogs());
  }, []);

  return (
    <>
      <Meta pageName="logs" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>logs</Breadcrumb.Item>
        </Breadcrumb>
        <S.WorkSpace>
          <LogsList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Logs;
