import React, { useState } from "react";
import type { TableProps } from "antd";
import * as S from "./Table.styled";
import { Table as TableAnt, Tag } from "antd";
import { ILog, ITableProps } from "features/LogsList/interfaces";
import moment from "moment";
import { uniq } from "@antv/util";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";

const typeColors = {
  login: "blue",
  registration: "green",
  forgot: "purple",
};

const Table: React.FC<ITableProps> = ({ items }) => {
  // Сортировка в табличке
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ILog>>({
    order: "descend",
    columnKey: "logDate",
  });

  const emails = uniq(items.map((item: ILog) => item.email)).map(
    (email: string) => ({ text: email, value: email })
  );
  // console.log(sort);
  // Конфиг таблички для Ant Table

  const columns: ColumnsType<ILog> = [
    {
      title: "Date",
      dataIndex: "logDate",
      key: "logDate",
      ellipsis: true,
      sorter: (a: ILog, b: ILog) => a.logDate - b.logDate,
      render: (createdDate: number) =>
        moment(createdDate).format("DD.MM.YYYY, h:mm:ss a"),
      sortOrder: sortedInfo.columnKey === "logDate" ? sortedInfo.order : null,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      filters: [
        {
          text: "Login",
          value: "login",
        },
        {
          text: "Registration",
          value: "registration",
        },
        {
          text: "Forgot",
          value: "forgot",
        },
      ],
      onFilter: (value: string, record) => record.type === value,
      render: (type: string) => <Tag color={typeColors[type]}>{type}</Tag>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      filters: emails,
      onFilter: (value: string, record) => record.email === value,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      ellipsis: true,
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onHandleChange: TableProps<ILog>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortedInfo(sorter as SorterResult<ILog>);
  };

  return (
    <S.Wrap>
      <TableAnt
        columns={columns}
        dataSource={items}
        pagination={false}
        onChange={onHandleChange}
      />
    </S.Wrap>
  );
};

export default Table;
