import React, { useState } from "react";
import * as S from "./Table.styled";
import { ICategory, IImage, IJob, IMark, IService } from "interfaces/common";
import { ITableProps, ITableSort } from "features/ServicesList/interfaces";
import { Table as TableAnt, Button, Tag, Popconfirm } from "antd";

const Table: React.FC<ITableProps> = ({
  items,
  isManager,
  isAdmin,
  allCategories,
  onEdit,
  onRemove,
}) => {
  // Сортировка в табличке
  const [sort, setSort] = useState<ITableSort>({
    order: null,
    columnKey: null,
  });

  // Конфиг таблички для Ant Table
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sortOrder: sort.columnKey === "category" && sort.order,
      ellipsis: true,
      width: 120,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.category < b.category) return -1;
          if (a.category > b.category) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (categoryId: string) => {
        switch (categoryId) {
          case "001":
            return (
              <Tag color="magenta">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "002":
            return (
              <Tag color="red">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "003":
            return (
              <Tag color="orange">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "004":
            return (
              <Tag color="gold">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "005":
            return (
              <Tag color="lime">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "006":
            return (
              <Tag color="green">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "007":
            return (
              <Tag color="cyan">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "008":
            return (
              <Tag color="blue">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
          case "009":
            return (
              <Tag color="purple">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );

          default:
            return (
              <Tag color="magenta">
                {allCategories
                  .find((item: ICategory) => item.id === categoryId)
                  ?.title.toLocaleLowerCase() || "category"}
              </Tag>
            );
        }
      },
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      sortOrder: sort.columnKey === "title" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Address",
      dataIndex: "description",
      key: "description",
      sortOrder: sort.columnKey === "description" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        },
        multiple: 2,
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sortOrder: sort.columnKey === "phone" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.phone < b.phone) return -1;
          if (a.phone > b.phone) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      sortOrder: sort.columnKey === "site" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.site < b.site) return -1;
          if (a.site > b.site) return 1;
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortOrder: sort.columnKey === "email" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.email < b.email) return -1;
          if (a.email > b.email) return 1;
          return 0;
        },
        multiple: 5,
      },
    },
    {
      title: "Organization ID",
      dataIndex: "inn",
      key: "inn",
      sortOrder: sort.columnKey === "inn" && sort.order,
      ellipsis: true,
      width: isAdmin ? null : 0,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.inn < b.inn) return -1;
          if (a.inn > b.inn) return 1;
          return 0;
        },
        multiple: 5,
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      sortOrder: sort.columnKey === "services" && sort.order,
      ellipsis: true,
      width: isAdmin ? 100 : 0,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.services.length < b.services.length) return -1;
          if (a.services.length > b.services.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (services: IJob[]) => services.length,
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      sortOrder: sort.columnKey === "images" && sort.order,
      ellipsis: true,
      width: 95,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.images.length < b.images.length) return -1;
          if (a.images.length > b.images.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (images: IImage[]) => images.length,
    },
    {
      title: "Followers",
      dataIndex: "favorites",
      key: "favorites",
      sortOrder: sort.columnKey === "favorites" && sort.order,
      ellipsis: true,
      width: 110,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.favorites.length < b.favorites.length) return -1;
          if (a.favorites.length > b.favorites.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (favorites: string[]) => favorites.length,
    },
    {
      title: "Brands",
      dataIndex: "brands",
      key: "brands",
      sortOrder: sort.columnKey === "brands" && sort.order,
      ellipsis: true,
      width: isAdmin ? 90 : 0,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.brands.length < b.brands.length) return -1;
          if (a.brands.length > b.brands.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (brands: string[]) => brands.length,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
      sortOrder: sort.columnKey === "latitude" && sort.order,
      ellipsis: true,
      width: isAdmin ? null : 0,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.latitude < b.latitude) return -1;
          if (a.latitude > b.latitude) return 1;
          return 0;
        },
        multiple: 6,
      },
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
      sortOrder: sort.columnKey === "longitude" && sort.order,
      ellipsis: true,
      width: isAdmin ? null : 0,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.longitude < b.longitude) return -1;
          if (a.longitude > b.longitude) return 1;
          return 0;
        },
        multiple: 6,
      },
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      sortOrder: sort.columnKey === "marks" && sort.order,
      ellipsis: true,
      width: 90,
      sorter: {
        compare: (a: IService, b: IService) => {
          if (a.marks < b.marks) return -1;
          if (a.marks > b.marks) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (marks: IMark[]) => marks.length,
    },
    {
      title: "",
      key: "action",
      render: (_: string, record: IService) => {
        return (
          <S.Actions>
            <Button
              onClick={() => onEdit(record)}
              size="small"
              disabled={!isManager}
            >
              edit
            </Button>
            <Popconfirm
              placement="rightBottom"
              title="Are you sure to delete this organization?"
              onConfirm={() => onRemove(record.id)}
              okText="delete"
              cancelText="cancel"
              disabled={!isManager}
            >
              <Button size="small" disabled={!isManager}>
                delete
              </Button>
            </Popconfirm>
          </S.Actions>
        );
      },
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onChange = (_: any, filters: any, sorter: any) => {
    setSort(sorter);
  };

  return (
    <S.Wrap>
      <TableAnt
        columns={columns}
        dataSource={items}
        pagination={false}
        onChange={onChange}
      />
    </S.Wrap>
  );
};

export default Table;
