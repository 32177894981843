import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { RootState } from "store/store";
import { IServicesListState } from "./interfaces";
import { IService } from "interfaces/common";
import {
  addOrganization,
  saveOrganization,
} from "features/ServiceModal/ServiceModalSlice";
import { auth, db } from "../../firebase";

// Selectors
export const ServicesListSelector = (state: RootState): IServicesListState =>
  state.servicesList;
export const ServicesListLoadingSelector = createSelector(
  ServicesListSelector,
  ({ isLoading }) => isLoading
);
export const ServicesListItemsSelector = createSelector(
  ServicesListSelector,
  ({ items }) => items
);
export const QuerySelector = createSelector(
  ServicesListSelector,
  ({ query }) => query
);

// Actions
export const setQuery = createAction("services/setQuery", (query: string) => ({
  payload: query,
}));

export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async (isAdmin: boolean, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      const uid = user?.uid;

      const params = isAdmin
        ? query(collection(db, "organizations"))
        : query(
            collection(db, "organizations"),
            where("organizationId", "==", uid)
          );

      const response = await getDocs(params);
      const docs: IService[] = response.docs.map((snap) => {
        const document = snap.data();

        return {
          id: snap.id,
          avatar: document.avatar,
          title: String(document.title),
          description: String(document.description),
          organizationId: String(document.organizationId),
          phone: String(document.phone),
          site: String(document.site),
          email: String(document.email),
          category: String(document.category),
          inn: String(document.inn),
          longitude: String(document.longitude),
          latitude: String(document.latitude),
          lifts: Number(document.lifts),
          brands: document.brands,
          tags: document.tags,
          schedule: document.schedule,
          services: document.services,
          favorites: document.favorites,
          favoritesCount: document.favoritesCount,
          images: document.images,
          marks: document?.marks || [],
        };
      });

      return docs;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление существующего тех. центра
export const removeOrganization = createAsyncThunk(
  "services/removeOrganization",
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteDoc(doc(db, "organizations", id));

      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IServicesListState = {
  isLoading: false,
  items: [],
  query: "",
};

const servicesListSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setQuery: (state, { payload }) => ({
      ...state,
      query: payload.toLocaleLowerCase(),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchServices.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchServices.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }))
      .addCase(addOrganization.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(addOrganization.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(addOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: [...state.items, payload],
      }))
      .addCase(saveOrganization.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(saveOrganization.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(saveOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.map((item: IService) =>
          item.id === payload.id
            ? {
                ...item,
                ...payload.data,
              }
            : item
        ),
      }))
      .addCase(removeOrganization.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(removeOrganization.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(removeOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.filter((item: IService) => item.id !== payload),
      }));
  },
});

export default servicesListSlice.reducer;
