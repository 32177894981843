import { Layout, Button } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import Navbar from "components/Navbar";
import Loader from "components/Loader";
import {
  fetchMyData,
  IsAdminSelector,
  IsAuthenticatedSelector,
  removeUser,
  setUser,
} from "../features/User/UserSlice";
import Windows from "components/Windows";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import Login from "pages/Login";
import SignUp from "pages/SignUp";

import PrivateRoutes from "privateRoutes";
import "./App.css";
import { auth } from "../firebase";
import {
  fetchBrands,
  fetchCategories,
  fetchMeasures,
  fetchPeriods,
  fetchStatuses,
} from "features/Libraries/LibrariesSlice";
import { fetchLibrary } from "features/Library/LibrarySlice";

const { Header, Footer } = Layout;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const isAuthenticated = useAppSelector(IsAuthenticatedSelector);
  const isAdmin = useAppSelector(IsAdminSelector);

  useEffect(() => {
    const haveUser = localStorage.getItem("auto_saved_user");

    if (haveUser) {
      const curUser = JSON.parse(haveUser);
      signInWithEmailAndPassword(auth, curUser.email, curUser.password)
        .then(({ user }) => {
          const newUser = {
            email: user.email,
            uid: user.uid,
            token: user.refreshToken,
            password: curUser.password,
          };

          dispatch(setUser(newUser));
          push("/");
          localStorage.setItem("auto_saved_user", JSON.stringify(newUser));

          return newUser;
        })
        .then((user) => {
          dispatch(fetchMyData(user.uid));
          dispatch(fetchBrands());
          dispatch(fetchMeasures());
          dispatch(fetchCategories());
          dispatch(fetchStatuses());
          dispatch(fetchPeriods());
          dispatch(fetchLibrary());
        })
        .catch(() => alert("Invalid user!"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogOut = () => {
    dispatch(removeUser());
    localStorage.removeItem("auto_saved_user");
    push("/");
  };

  return (
    <Layout className="app">
      {isAuthenticated ? (
        <>
          <Header className="header">
            <Navbar isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
            <Button onClick={onLogOut}>Log out</Button>
          </Header>
          <PrivateRoutes isAdmin={isAdmin} />
          <Footer className="footer">CAR SERVICES MANAGER | carcity.ae</Footer>
        </>
      ) : (
        <>
          <Redirect to="/login" />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <SignUp />
          </Route>
        </>
      )}
      <Loader />
      <Windows />
    </Layout>
  );
};

export default App;
