import styled from "styled-components";

export const User = styled.div`
    width: 390px;
    height: 100%;
    padding-right: 30px;
    border-right: 1px solid #F0F0F0;
`;

export const Field = styled.div`
    :not(:first-of-type) {
        margin-top: 12px;
    }
`;

export const Label = styled.div`
    line-height: 22px;
    font-size: 14px;
    color: #8C8C8C;
`;

export const Value = styled.div`
    margin-top: 4px;
    color: #262626;
    line-height: 28px;
    font-size: 20px;
`;
