import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks/useStore";
import IconSVG from "assets/images/5.svg";
import { fetchMyData, setUser } from "features/User/UserSlice";
import { KeyOutlined, MailOutlined } from "@ant-design/icons";

import * as S from "./Login.styled";
import { auth } from "../../firebase";
import {
  fetchBrands,
  fetchCategories,
  fetchMeasures,
  fetchPeriods,
  fetchStatuses,
} from "features/Libraries/LibrariesSlice";
import { fetchLibrary } from "features/Library/LibrarySlice";
import Meta from "components/Meta";

const { Title, Text } = Typography;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const onHandleLogin = ({
    email,
    password,
    remember,
  }: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        const newUser = {
          email: user?.email || "",
          uid: user.uid,
          token: user.refreshToken,
        };

        dispatch(setUser(newUser));
        push("/");

        if (remember)
          localStorage.setItem(
            "auto_saved_user",
            JSON.stringify({ ...newUser, password })
          );

        return newUser;
      })
      .then((user) => {
        dispatch(fetchMyData(user.uid));
        dispatch(fetchBrands());
        dispatch(fetchMeasures());
        dispatch(fetchCategories());
        dispatch(fetchStatuses());
        dispatch(fetchLibrary());
        dispatch(fetchPeriods());
      })
      .catch(() => alert("Invalid user!"));
  };

  const onError = () => alert("Invalid user!");

  return (
    <>
      <Meta pageName="login" />
      <S.Login>
        <S.Form>
          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            style={{ borderRadius: "3px" }}
            initialValues={{ remember: true }}
            onFinish={onHandleLogin}
            onFinishFailed={onError}
            autoComplete="off"
            className="form"
          >
            <S.Head>
              <img
                src={IconSVG}
                alt="Carcity"
                style={{ maxHeight: 144, marginBottom: 20 }}
              />
              <Title level={5} style={{ marginBottom: "4px" }}>
                Carcity
              </Title>
              <Text type="secondary">
                Managment platform of car service centers
              </Text>
            </S.Head>
            <Form.Item
              name="email"
              style={{ marginBottom: 14 }}
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                placeholder="Email"
                prefix={
                  <MailOutlined style={{ marginRight: 4, color: "#B7B7B7" }} />
                }
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
              style={{ marginBottom: 14 }}
            >
              <Input.Password
                placeholder="Password"
                prefix={
                  <KeyOutlined style={{ marginRight: 4, color: "#B7B7B7" }} />
                }
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ marginBottom: 14 }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ marginBottom: 14 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ marginBottom: 0 }}
            >
              <Link to="/register">
                <Button style={{ width: "100%" }} htmlType="button">
                  Sign up
                </Button>
              </Link>
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 0, span: 24 }}
              style={{ marginBottom: 0 }}
            >
              <S.Policy>
                By signing up I agree to the{" "}
                <a
                  href="https://carcity.ae/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </S.Policy>
            </Form.Item>
          </Form>
        </S.Form>
      </S.Login>
    </>
  );
};

export default Login;
