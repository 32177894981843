import React from "react";
import * as S from "./RecordsList.styled";
import { useSelector } from "react-redux";
import { removeRecord, RecordsListItemsSelector } from "./RecordsListSlice";
import Table from "./components/Table";
import { useAppDispatch } from "hooks/useStore";
import { openOldRecordModal } from "features/RecordModal/RecordModalSlice";
import { IRecord } from "./interfaces";
import {
  BrandsSelector,
  StatusSelector,
} from "features/Libraries/LibrariesSlice";
import { IsManagerSelector } from "features/User/UserSlice";

const RecordsList: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const records = useSelector(RecordsListItemsSelector);
  const isManager = useSelector(IsManagerSelector);

  // Все вожможные статусы
  const allStatuses = useSelector(StatusSelector);

  // Все вожможные бренды автомобилей
  const allBrands = useSelector(BrandsSelector);

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onOpenOldRecordModal = (record: IRecord) =>
    dispatch(openOldRecordModal(record));

  // Удаление сервисного центра
  const onRemoveService = (id: string) => dispatch(removeRecord(id));

  return (
    <S.Wrap>
      <Table
        items={records}
        isManager={isManager}
        allStatuses={allStatuses}
        allBrands={allBrands}
        onEdit={onOpenOldRecordModal}
        onRemove={onRemoveService}
      />
    </S.Wrap>
  );
};

export default RecordsList;
