import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { auth, db } from "../../firebase";
import { IBrand, ITag } from "interfaces/common";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { RootState } from "store/store";
import { IRecordData, IRecordModalState } from "./interfaces";
import { IRecord } from "features/RecordsList/interfaces";
import moment from "moment";

// Открытие окна с новой целью
export const openNewRecordModal = createAction(
  "recordModal/openNewRecordModal"
);

// Открытие окна с существующей целью
export const openOldRecordModal = createAction(
  "recordModal/openOldRecordModal",
  (record: IRecord) => ({ payload: record })
);

// Открытие окна с новой целью
export const closeRecordModal = createAction("recordModal/closeRecordModal");

// Изменение полей
export const changeField = createAction(
  "recordModal/changeField",
  (field: string, value: string | number) => ({ payload: { field, value } })
);

// Изменение тегов
export const changeTags = createAction(
  "recordModal/changeTags",
  (tags: ITag[]) => ({ payload: tags })
);

// Изменение фирм
export const changeBrands = createAction(
  "recordModal/changeBrands",
  (brands: IBrand[]) => ({ payload: brands })
);

// Изменение статуса
export const changeStatus = createAction(
  "recordModal/changeStatus",
  (id: string) => ({ payload: id })
);

// Добавление периода
export const addPeriod = createAction("recordModal/addPeriod");

// Изменение периода
export const changePeriod = createAction(
  "recordModal/changePeriod",
  (id: string, value: string, field: string) => ({
    payload: { id, value, field },
  })
);

// Удаление периода
export const removePeriod = createAction(
  "recordModal/removePeriod",
  (id: string) => ({ payload: id })
);

// Добавление услуги
export const addService = createAction("recordModal/addService");

// Изменение услуги
export const changeService = createAction(
  "recordModal/changeService",
  (id: string, value: string | number, field: string) => ({
    payload: { id, value, field },
  })
);

// Удаление услуги
export const removeService = createAction(
  "recordModal/removeService",
  (id: string) => ({ payload: id })
);

// Добавление избранного
export const addFavorites = createAction(
  "recordModal/addFavorites",
  (value: string) => ({ payload: value })
);

// Удаление избранного
export const removeFavorites = createAction(
  "recordModal/removeFavorites",
  (id: string) => ({ payload: id })
);

// Добавление нового сервиса
export const addRecord = createAsyncThunk(
  "recordModal/addRecord",
  async (newDocData: IRecordData, { rejectWithValue }) => {
    try {
      const docRef = await addDoc(collection(db, "records"), newDocData);
      const newDoc: IRecord = {
        ...newDocData,
        id: docRef.id,
      };

      return newDoc;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Изменение данных организации сервиса
export const saveRecord = createAsyncThunk(
  "recordModal/saveRecord",
  async (
    { id, data }: { id: string; data: IRecordData },
    { rejectWithValue }
  ) => {
    try {
      await setDoc(doc(db, "records", id), data, { merge: true });

      return { id, data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IRecordModalState = {
  id: "",
  carBrandId: "",
  carModel: "",
  carYear: moment().format("YYYY"),
  carVin: "",
  clientComment: "",
  clientId: "",
  clientName: "",
  clientPhone: "",
  meetDate: moment().valueOf(),
  organizationComment: "",
  organizationId: "",
  organizationName: "",
  organizationType: "",
  services: [],
  status: "003",
  isVisible: false,
  isLoading: false,
};

const recordModalSlice = createSlice({
  name: "recordModal",
  initialState,
  reducers: {
    openNewRecordModal: () => ({
      ...initialState,
      isVisible: true,
      organizationId: auth?.currentUser?.uid,
    }),
    closeRecordModal: () => initialState,
    openOldRecordModal: (_, { payload }) => ({
      ...initialState,
      ...payload,
      isVisible: true,
    }),
    changeField: (state, { payload }) => ({
      ...state,
      [payload.field]: payload.value,
    }),
    changeTags: (state, { payload }) => ({
      ...state,
      tags: payload,
    }),
    addService: (state) => ({
      ...state,
      services: [
        ...state.services,
        {
          id: Date.now().toString(),
          serviceName: "",
          price: 10,
        },
      ],
    }),
    changeService: (state, { payload }) => ({
      ...state,
      services: state.services.map((period) =>
        period.id === payload.id
          ? {
              ...period,
              [payload.field]: payload.value,
            }
          : period
      ),
    }),
    removeService: (state, { payload }) => ({
      ...state,
      services: state.services.filter((service) => service.id !== payload),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRecord.fulfilled, (state) => ({ ...state, isVisible: false }))
      .addCase(saveRecord.fulfilled, (state) => ({
        ...state,
        isVisible: false,
      }));
  },
});

// Селекторы
export const RecordModalSelector = (state: RootState): IRecordModalState =>
  state.recordModal;
export const RecordModalLoadingSelector = createSelector(
  RecordModalSelector,
  ({ isLoading }) => isLoading
);
export const RecordModalVisibleSelector = createSelector(
  RecordModalSelector,
  ({ isVisible }) => isVisible
);

export default recordModalSlice.reducer;
