import React, { useState } from "react";
import * as S from "./Table.styled";
import { IBrand, IJob, IStatus } from "interfaces/common";
import { Table as TableAnt, Button, Tag, Popconfirm } from "antd";
import {
  IRecord,
  ITableProps,
  ITableSort,
} from "features/RecordsList/interfaces";
import moment from "moment";

const Table: React.FC<ITableProps> = ({
  items,
  isManager,
  allStatuses,
  allBrands,
  onEdit,
  onRemove,
}) => {
  // Сортировка в табличке
  const [sort, setSort] = useState<ITableSort>({
    order: null,
    columnKey: null,
  });

  // Конфиг таблички для Ant Table
  const columns = [
    {
      title: "Brand",
      dataIndex: "carBrandId",
      key: "carBrandId",
      sortOrder: sort.columnKey === "carBrandId" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.carBrandId < b.carBrandId) return -1;
          if (a.carBrandId > b.carBrandId) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (carBrandId: string) =>
        allBrands.find((item: IBrand) => item.id === carBrandId)?.name || "",
    },
    {
      title: "Model",
      dataIndex: "carModel",
      key: "carModel",
      sortOrder: sort.columnKey === "carModel" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.carModel < b.carModel) return -1;
          if (a.carModel > b.carModel) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Year",
      dataIndex: "carYear",
      key: "carYear",
      sortOrder: sort.columnKey === "carYear" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.carYear < b.carYear) return -1;
          if (a.carYear > b.carYear) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "VIN",
      dataIndex: "carVin",
      key: "carVin",
      sortOrder: sort.columnKey === "carVin" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.carVin < b.carVin) return -1;
          if (a.carVin > b.carVin) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Name",
      dataIndex: "clientName",
      key: "clientName",
      sortOrder: sort.columnKey === "clientName" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.clientName < b.clientName) return -1;
          if (a.clientName > b.clientName) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Phone",
      dataIndex: "clientPhone",
      key: "clientPhone",
      sortOrder: sort.columnKey === "clientPhone" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.clientPhone < b.clientPhone) return -1;
          if (a.clientPhone > b.clientPhone) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Client's comment",
      dataIndex: "clientComment",
      key: "clientComment",
      sortOrder: sort.columnKey === "clientComment" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.clientComment < b.clientComment) return -1;
          if (a.clientComment > b.clientComment) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Date",
      dataIndex: "meetDate",
      key: "meetDate",
      sortOrder: sort.columnKey === "meetDate" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.meetDate < b.meetDate) return -1;
          if (a.meetDate > b.meetDate) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (meetDate: number) => moment(meetDate).format("DD.MM.YYYY"),
    },
    {
      title: "Organization's comment",
      dataIndex: "organizationComment",
      key: "organizationComment",
      sortOrder: sort.columnKey === "organizationComment" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.organizationComment < b.organizationComment) return -1;
          if (a.organizationComment > b.organizationComment) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sortOrder: sort.columnKey === "status" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (_, { status }) => {
        const myStatus = allStatuses.find(
          (item: IStatus) => item.id === status
        );
        return (
          <Tag color={myStatus.color} key={myStatus.id}>
            {myStatus.name}
          </Tag>
        );
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      sortOrder: sort.columnKey === "services" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.services.length < b.services.length) return -1;
          if (a.services.length > b.services.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (services: IJob[]) => (
        <S.Services>
          {services.map((item: IJob) => (
            <Tag key={item.id}>{item.serviceName}</Tag>
          ))}
        </S.Services>
      ),
    },
    {
      title: "",
      key: "action",
      width: 128,
      render: (_: string, record: IRecord) => {
        return (
          <S.Actions>
            <Button
              onClick={() => onEdit(record)}
              disabled={!isManager}
              size="small"
            >
              edit
            </Button>
            <Popconfirm
              placement="rightBottom"
              title="Are you sure to delete this request?"
              onConfirm={() => onRemove(record.id)}
              okText="delete"
              cancelText="cancel"
              disabled={!isManager}
            >
              <Button disabled={!isManager} size="small">
                remove
              </Button>
            </Popconfirm>
          </S.Actions>
        );
      },
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onChange = (_: any, filters: any, sorter: any) => {
    setSort(sorter);
  };

  return (
    <S.Wrap>
      <TableAnt columns={columns} dataSource={items} onChange={onChange} />
    </S.Wrap>
  );
};

export default Table;
