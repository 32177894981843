import React, { useState } from 'react';
import * as S from "./Table.styled";
import { Table as TableAnt } from 'antd';
import { IMessage, ITableProps, ITableSort } from 'features/MessagesList/interfaces';

const Table: React.FC<ITableProps> = ({
    items,
}) => {
    // Сортировка в табличке
    const [sort, setSort] = useState<ITableSort>({
        order: null,
        columnKey: null
    });

    // Конфиг таблички для Ant Table
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sortOrder: sort.columnKey === 'name' && sort.order,
            ellipsis: true,
            sorter: {
                compare: (a:IMessage, b:IMessage) => {
                    if (a.name < b.name)
                        return -1
                    if (a.name > b.name)
                        return 1
                    return 0
                },
                multiple: 1,
            }
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sortOrder: sort.columnKey === 'phone' && sort.order,
            ellipsis: true,
            sorter: {
                compare: (a:IMessage, b:IMessage) => {
                    if (a.phone < b.phone)
                        return -1
                    if (a.phone > b.phone)
                        return 1
                    return 0
                },
                multiple: 1,
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sortOrder: sort.columnKey === 'email' && sort.order,
            ellipsis: true,
            sorter: {
                compare: (a:IMessage, b:IMessage) => {
                    if (a.email < b.email)
                        return -1
                    if (a.email > b.email)
                        return 1
                    return 0
                },
                multiple: 1,
            }
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            sortOrder: sort.columnKey === 'message' && sort.order,
            ellipsis: true,
            sorter: {
                compare: (a:IMessage, b:IMessage) => {
                    if (a.message < b.message)
                        return -1
                    if (a.message > b.message)
                        return 1
                    return 0
                },
                multiple: 1,
            }
        },
    ];

    // Изменение сортировки и фильтрации в табличке
    const onChange = (_: any, filters: any, sorter: any) => {
        setSort(sorter);
    };

    return (
        <S.Wrap>
            <TableAnt
                columns={columns}
                dataSource={items}
                onChange={onChange}
            />
        </S.Wrap>
    )
}

export default Table;

