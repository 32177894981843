import React from 'react';
import * as S from "./MessagesList.styled";
import { useSelector } from 'react-redux';
import Table from './components/Table';
import { MessagesListItemsSelector } from './MessagesListSlice';

const MessagesList: React.FC = () => {
    // Список загруженных сервисных. центров
    const messages = useSelector(MessagesListItemsSelector);

    return (
        <S.Wrap>
            <Table items={messages} />
        </S.Wrap>
    )
}

export default MessagesList;