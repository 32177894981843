import { ServiceModalLoadingSelector } from "features/ServiceModal/ServiceModalSlice";
import { ServicesListLoadingSelector } from "features/ServicesList/ServicesListSlice";
import { MessagesListLoadingSelector } from "features/MessagesList/MessagesListSlice";
import { RecordModalLoadingSelector } from "features/RecordModal/RecordModalSlice";
import { RecordsListLoadingSelector } from "features/RecordsList/RecordsListSlice";
import { LogsListLoadingSelector } from "features/LogsList/LogsListSlice";
import {
  BrandsLoadingSelector,
  CategoriesLoadingSelector,
  PeriodsLoadingSelector,
  StatusesLoadingSelector,
  TagsLoadingSelector,
  MeasuresLoadingSelector,
} from "features/Libraries/LibrariesSlice";
import { useAppSelector } from "hooks/useStore";

import "./Loader.css";
import { UserLoadingSelector } from "features/User/UserSlice";
import { UsersListLoadingSelector } from "features/UsersList/UsersListSlice";

const Loader = () => {
  const userLoading = useAppSelector(UserLoadingSelector);
  const usersListLoading = useAppSelector(UsersListLoadingSelector);
  const servicesLoading = useAppSelector(ServicesListLoadingSelector);
  const messagesLoading = useAppSelector(MessagesListLoadingSelector);
  const serviceModalLoading = useAppSelector(ServiceModalLoadingSelector);
  const recordsLoading = useAppSelector(RecordsListLoadingSelector);
  const recordModalLoading = useAppSelector(RecordModalLoadingSelector);
  const brandsLoading = useAppSelector(BrandsLoadingSelector);
  const categoriesLoading = useAppSelector(CategoriesLoadingSelector);
  const periodsLoading = useAppSelector(PeriodsLoadingSelector);
  const statusesLoading = useAppSelector(StatusesLoadingSelector);
  const tagsLoading = useAppSelector(TagsLoadingSelector);
  const measuresLoading = useAppSelector(MeasuresLoadingSelector);
  const logsListLoading = useAppSelector(LogsListLoadingSelector);

  return (
    <>
      {(messagesLoading ||
        servicesLoading ||
        serviceModalLoading ||
        recordsLoading ||
        recordModalLoading ||
        brandsLoading ||
        categoriesLoading ||
        periodsLoading ||
        tagsLoading ||
        userLoading ||
        usersListLoading ||
        measuresLoading ||
        logsListLoading ||
        statusesLoading) && (
        <div className="modal-backdrop">
          <span className="spinner"></span>
        </div>
      )}
    </>
  );
};

export default Loader;
