import React, { useEffect } from "react";
import { Layout, Breadcrumb, Button, Divider, Input } from "antd";
import * as S from "./MyServices.styled";
import ServicesList from "features/ServicesList";
import { useAppDispatch } from "hooks/useStore";
import User from "features/User";
import {
  fetchServices,
  QuerySelector,
  ServicesListItemsSelector,
  setQuery,
} from "features/ServicesList/ServicesListSlice";
import { openNewServiceModal } from "features/ServiceModal/ServiceModalSlice";
import { useSelector } from "react-redux";
import { IsManagerSelector } from "features/User/UserSlice";
import Meta from "components/Meta";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;

const MyServices: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const services = useSelector(ServicesListItemsSelector);
  const acceptedManager = useSelector(IsManagerSelector);
  const query = useSelector(QuerySelector);

  useEffect(() => {
    dispatch(fetchServices(false));
  }, []);

  const onOpenNewServiceModal = () => dispatch(openNewServiceModal());

  const onSearch = (event) => dispatch(setQuery(event.target.value));

  return (
    <>
      <Meta pageName="myServices" />
      <Content
        style={{
          padding: "0px 20px 20px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>managment platform</Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            {`user panel, (${services.length}) organizations`}
          </Breadcrumb.Item>
        </Breadcrumb>
        <S.MyServices>
          <User />
          <Divider type="vertical" style={{ height: "auto" }} />
          <S.WorkSpace>
            <S.Search>
              <Input
                type="text"
                placeholder="Search"
                name="search"
                size="large"
                prefix={<S.SearchIcon><SearchOutlined style={{ color: "#B7B7B7" }} /></S.SearchIcon>}
                value={query}
                onChange={onSearch}
                allowClear
              />
            </S.Search>
            <S.Header>
              <Button
                type="primary"
                size="large"
                onClick={onOpenNewServiceModal}
                block
                disabled={!acceptedManager}
              >
                Add technical center
              </Button>
            </S.Header>
            <ServicesList />
          </S.WorkSpace>
        </S.MyServices>
      </Content>
    </>
  );
};

export default MyServices;
