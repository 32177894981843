import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import './firebase';
import './index.css';
import App from './app';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from './store';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
, document.getElementById('root'));
